import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";
import { Injectable } from "@angular/core";
import { ICONS_PATH } from 'src/app/config/path';

@Injectable({
  providedIn: 'root'
})
export class CustomIconService {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
  }

  init() {
    const icons = [
      "analyze", "anomaly-icon", "vertical-marker",
      "severity_high", "severity_medium", "severity_low", "severity_critical", "severity_not_computed",
      "operations", "security", "settings", "device", "accounts", "services",
      "notifications", "notification", "venue", "search", "fabric", "hub-icon", "spoke-icon", "two-ways-arrow",
      "dashboard", "organization", "organization2", "tenant", "toggle", "client",
      "advanced", "fabric_circle", "complete", "incomplete", "no-www-connectivity", "megaphone-alarms",
      "clock", "clients", "actions-description", "health-issues-icon", "information2", "legend-icon", "chart-close-icon",
      "hint-robot", "edit-layout", "edit-layout-2", "resize", "color-picker", "color-picker2", "delete-trash", "widgets-manager-icon",
      "left-arrow", "right-arrow", "resize", "circle-list-icon", "smile",
      "timeline-marker", "maintenance", "maintenance -1", "reports", "globe", "port-icon", "ip-icon", "protocol", "http",
      "app", 'ssid', 'vlan', 'policy', 'port', 'small-dot', 'minimize-icon', 'fix-icon', 'analyze-icon', 'v-icon', 'sync-button', 'failure', 'integration',
      'unsupported', "switch-stack"
    ]
    icons.map(icon => this.matIconRegistry.addSvgIcon(
      icon,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/media/netop/${icon}.svg`)
    ));
    const actions = [
      "add_circle", "delete", "view_eye", "edit", "expand_tree_node",
      "expanded_tree_node", "manage_tenant", "edit_users"
    ]
    actions.map(icon => this.matIconRegistry.addSvgIcon(
      icon,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/media/netop/actions/${icon}.svg`)
    ));

    const topology = ["small-ellipse", "router", "access-point", "google-cloud-icon", "aws-cloud-icon", "click-with-arrow"];
    topology.map(icon => this.matIconRegistry.addSvgIcon(
      icon,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/media/netop/topology/${icon}.svg`)
    ));
  }

  /**
   * Resolve full icon path using relative one
   * @param path the relative icon path
   */
  getFullIconPath(path: string) {
    return ICONS_PATH + path;
  }
}
